<template>
  <b-modal
    id="modal-installment-calculator"
    size="md"
    centered
    scrollable
    hide-footer
  >
    <template #modal-header>
      <h5 class="modal-title">Estimasi Cicilan</h5>
      <button class="btn-close-modal-custom" @click="$bvModal.hide('modal-installment-calculator')">&times;</button>
    </template>
    <div class="installment-info">
      <fawesome-pro variant="far" icon="bell" size="sm" class="installment-info-logo pr-2" /> 
      <span>Harga yang tertera merupakan estimasi cicilan</span>
    </div>
    <div class="mb-3" v-for="(installment, installmentIndex) in installmentCalculator" :key="installmentIndex">
    <div v-b-toggle="`accordion-${installmentIndex}`" class="d-flex align-items-center justify-content-start">
      <div class="installment-img-container mr-2">
        <b-img :src="installment.image_url" alt=""></b-img>
      </div>
      <b class="installment-name">{{ installment.name }}</b>
      <fawesome-pro variant="far" icon="chevron-down" size="sm" :class="`ml-auto accordion-chevron-${installmentIndex} ${installmentIndex == 0 ? 'collapse-active' : ''}`" />
    </div>
    <b-collapse :id="`accordion-${installmentIndex}`" :visible="installmentIndex == 0 ? true : false" role="tabpanel" @hide="onHideCollapse(installmentIndex)" @show="onShowCollapse(installmentIndex)" >
      <div
        v-for="(detail, detailIndex) in installment.details"
        class="d-flex align-items-center justify-content-between py-2"
        :class="{ 'border-bottom': detailIndex < installment.details.length - 1 }"
        :key="detailIndex"
      >
        <b class="installment-tenure">{{ detail.tenure }} x</b>
        <div class="d-flex flex-column align-items-end">
          <b class="monthly-installment">{{ $helpers.format.price(detail.monthly_instalment) }} <span class="font-12px installment-grey-text">/bulan</span></b>
          <div class="font-12px installment-grey-text">{{ detail.interest_rate ? `Bunga Cicilan: ${detail.interest_rate}%` : `Tanpa Bunga Cicilan` }}</div>
        </div>
      </div>
    </b-collapse>
  </div>
  </b-modal>
</template>

<script>
export default {
  name: "ModalInstallmentCalculator",
  props: ["installmentCalculator"],
  methods: {
    onShowCollapse(index) {
      document.querySelector(`.accordion-chevron-${index}`).classList.toggle("collapse-active");
    },
    onHideCollapse(index) {
      document.querySelector(`.accordion-chevron-${index}`).classList.toggle("collapse-active");
    }
  }
}
</script>
<style scoped>
.installment-info {
  display: flex;
  align-items: center;
  background: #FFF8E1;
  margin: 0px -15px .75rem;
  padding: .5rem 1rem;
}

@media only screen and (max-width: 992px) {
  .installment-info, .installment-name, .installment-tenure, .monthly-installment {
    font-size: 14px;
  }
}

.installment-img-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  object-fit: contain;
  width: 100px;
  height: 30px;
}

.installment-grey-text {
  color: #9E9E9E;
  font-weight: 300;
}
.collapse-active {
  transform: rotate(180deg);
}
</style>